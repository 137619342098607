.CustomBadge {
  @apply rounded-full;
  @apply border;
  @apply box-border;
  font-size: 12px;
}
/*SIZE*/
.CustomBadge--sm {
  @apply pt-spacing-xxs;
  @apply pr-spacing-md;
  @apply pb-spacing-xxs;
  @apply pl-spacing-md;
}

.CustomBadge--md {
  @apply pt-spacing-xxs;
  @apply pr-2.5;
  @apply pb-spacing-xxs;
  @apply pl-2.5;
}

.CustomBadge--lg {
  @apply pt-spacing-xs;
  @apply pr-spacing-lg;
  @apply pb-spacing-xs;
  @apply pl-spacing-lg;
}
/*Color*/
.CustomBadge--gray {
  @apply bg-grayNeutral-50;
  @apply border-grayLight-200;
  @apply text-grayLight-700;
}
.CustomBadge--brand {
  @apply bg-brand-50;
  @apply border-brand-200;
  @apply text-brand-700;
}
.CustomBadge--error {
  @apply bg-error-50;
  @apply border-error-200;
  @apply text-error-700;
}
.CustomBadge--warning {
  @apply bg-warning-50;
  @apply border-warning-200;
  @apply text-warning-700;
}
.CustomBadge--success {
  @apply bg-success-50;
  @apply border-success-200;
  @apply text-success-700;
  @apply text-blue-800
}
.CustomBadge--grayBlue {
}
.CustomBadge--blueLight {
}
.CustomBadge--blue {
  @apply bg-blue-50;
  @apply border-blue-200;
}
.CustomBadge--indigo {
}
.CustomBadge--purple {
}
.CustomBadge--pink {
}
.CustomBadge--orange {
}
/*GAP*/
.CustomBadge__gap--sm {
  @apply gap-spacing-xs;
}
.CustomBadge__gap--md {
  @apply gap-spacing-sm;
}
.CustomBadge__gap--lg {
  @apply gap-spacing-sm;
}
/*TEXT*/
.CustomBadge__text--sm {
  font-size: 12px;
  @apply font-medium;
}
.CustomBadge__text--md {
  @apply text-sm;
  @apply font-medium;
}
.CustomBadge__text--lg {
  @apply text-sm;
  @apply font-medium;
}
.CustomBadge__text--blue{
  @apply text-blue-700
}
.CustomBadge__text--success{
  @apply text-success-700
}