.transition-transform {
    transition: transform 0.3s ease-in-out;
}

.translate-x-0 {
    transform: translateX(0);
}

.translate-x-full {
    transform: translateX(100%);
}

.SideMenu {
    transition: width 0.3s ease-in-out;
}

.SideMenu__logoContainer {
    transition: width 0.3s ease-in-out;
}

.SideMenu__logoContainer img {
    transition: width 1s ease-in-out;
}

.SideMenuButton p {
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    transform: translateX(-20px);
}

.SideMenuButton--expanded p {
    opacity: 1;
    transform: translateX(0);
}

.SideMenu__iconRotate {
    transition: transform 0.3s ease;
}

.SideMenu--collapsed .SideMenu__iconRotate {
    transform: rotate(180deg);
}

.SideMenu--expanded .SideMenu__iconRotate {
    transform: rotate(0deg);
}

