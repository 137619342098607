.Setting__sectionHeader {
  @apply flex;
  @apply flex-col;
  @apply gap-spacing-sm;
}

.Setting__sectionHeader > p:nth-of-type(1) {
  @apply text-base;
  @apply font-semibold;
  @apply text-grayLight-900;
}

.Setting__sectionHeader > p:nth-of-type(2) {
  @apply text-base;
  @apply font-normal;
  @apply text-grayLight-600;
}

.Settings {
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Prevent scrolling of the entire section */
}

.Settings__formContainer {
  flex-grow: 1; /* Allow the container to grow and take available space */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Ensure it takes the full width */
}

.Setting__sectionHeader p {
  margin: 0;
  padding: 0;
}
