.ParentAssignedAccounts__kidTab {
    box-shadow: 0 4px 8px -2px #1018281A;
}

.ParentAssignedAccounts__textArea{
    box-shadow: 0 1px 2px 0 #1018280D;
}

.ParentAssignedAccounts {
    max-height: calc(100vh - 66px);
    overflow-y: auto;
}