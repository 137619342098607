@import "table.style.css";

.mainHeaderOnPage {
    @apply font-semibold;
    @apply text-grayLight-900;
    font-size: 16px;
}
.mainHeaderOnPage__description {
    @apply font-normal;
    @apply text-grayLight-700;
    @apply text-sm
}

.no-icon-date-input::-webkit-calendar-picker-indicator {
    display: none;
}