.DishesDatabase__dishesBaseTab{
    box-shadow: 0px 1px 2px 0px #1018280F;
    box-shadow: 0px 1px 3px 0px #1018281A;

    @apply pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg text-grayLight-500 rounded-md text-sm font-semibold
}

.DishesDatabase__dishesBaseTab--active{
    @apply text-grayLight-700  bg-white
}

.DishesDatabase__dishesBaseTab__container{
    border-radius: 10px;
}