.ParentHeaderUsername__text{
    font-size: 12px;
    @apply text-gray-500;
}
.ParentHeader__basketContainer--shadow{
    transition: .3s;
    box-shadow: 0 0 0 4px #98A2B324;
}
.notificationCircle{
    top: -6px;
    right: -6px;
}
.basketPriceCircle{
    top: -8px;
    right: -8px;
}
.BasketContainer {
    @apply transition-all duration-300 fixed top-[71px] lg:top-[59px] lg:h-[calc(100%-59px)] h-[calc(100%-57px)] w-full md:w-[500px] right-0 bottom-0 z-50;
}