.odd-row {
    @apply bg-white
}
.even-row {
    @apply bg-brand-50
}

.first-row-th {
    height: 60px;
    vertical-align: middle;
}


.table-cell {
    @apply border-r;
    @apply border-r-gray-200;
}

.table-cell:last-child {
    @apply border-r-0;
}

.header-cell {
    @apply h-12;
    @apply border-r;
    @apply border-r-gray-200;
}

.header-cell:last-child {
    @apply border-r-0;
}