/*Hierarchy*/
.CustomChakraButton--primary {
  @apply bg-brand-300;
  @apply border-brand-300;
  @apply text-grayWarm-800;
}
.CustomChakraButton--primary:hover {
  @apply bg-brand-400;
  @apply border-brand-500;
  @apply text-grayWarm-800;
  transition: 0.3s;
}
.CustomChakraButton--primary:focus {
  @apply bg-brand-300;
  @apply border-brand-300;
  @apply text-grayWarm-800;
}
.CustomChakraButton--primary:disabled {
  @apply bg-grayLight-100;
  @apply border-grayLight-200;
  @apply text-grayLight-400;
}
.CustomChakraButton--secondaryGray {
}
.CustomChakraButton--secondaryColor {
  @apply bg-white;
  @apply border-brand-500;
  @apply text-grayWarm-700;
}
.CustomChakraButton--secondaryColor:hover {
  @apply bg-brand-50;
  @apply border-brand-300;
  @apply text-grayWarm-500;
  transition: 0.3s;
}
.CustomChakraButton--secondaryColor:focus {
  @apply bg-white;
  @apply border-brand-500;
  @apply text-grayWarm-700;
}
.CustomChakraButton--secondaryColor:disabled {
  @apply bg-white;
  @apply border-grayLight-200;
  @apply text-grayLight-400;
}
.CustomChakraButton--tertiaryGray {
}
.CustomChakraButton--tertiaryColor {
  @apply bg-brand-50;
  border: transparent;
}
.CustomChakraButton--tertiaryColor:hover {
  @apply bg-brand-50;
  border: transparent;
  transition: 0.3s;
}
.CustomChakraButton--tertiaryColor:focus {
  @apply bg-brand-50;
  border: transparent;
}
.CustomChakraButton--tertiaryColor:disabled {
  @apply bg-brand-50;
  border: transparent;
}
.CustomChakraButton--linkGray {
  border: transparent;
}
.CustomChakraButton--linkGray:hover {
}
.CustomChakraButton--linkGray:focus {
}
.CustomChakraButton--linkGray:disabled {
  @apply text-grayLight-400
}
.CustomChakraButton--linkColor {
}
.CustomChakraButton--warning {
  @apply bg-error-600;
  @apply border-error-600;
  @apply text-base-white;
}
.CustomChakraButton--warning:hover {
  @apply bg-error-700;
  @apply border-error-700;
  @apply text-base-white;
  transition: 0.3s;
}
.CustomChakraButton--warning:focus {
  @apply bg-error-600;
  @apply border-error-600;
  @apply text-base-white;
}
.CustomChakraButton--warning:disabled {
  @apply bg-grayLight-100;
  @apply border-grayLight-200;
  @apply text-grayLight-400;
}
/*Hierarchy destructive*/
.CustomChakraButton--destructiveSecondary{
  @apply bg-white;
  @apply border-error-300;
  @apply text-error-700;
}
.CustomChakraButton--destructiveSecondary:hover {
  @apply bg-error-50;
  @apply text-error-800;
  transition: 0.3s;
}
.CustomChakraButton--destructiveSecondary:focus {
  box-shadow: 0 0 0 4px #F044383D;
}
.CustomChakraButton--destructiveSecondary:disabled {
  @apply bg-white;
  @apply border-grayLight-200;
  @apply text-grayLight-400;
}
/*Size*/
.CustomChakraButton--sm {
  padding: 8px 12px;
  @apply rounded-md;
  @apply gap-spacing-xs;
}
.CustomChakraButton--md {
  padding: 10px 14px;
  @apply rounded-md;
  @apply gap-spacing-xs;
}
.CustomChakraButton--lg {
  padding-top: 10px;
  @apply pr-spacing-xl;
  padding-bottom: 10px;
  @apply pl-spacing-xl;
  border-radius: 10px;
  @apply gap-spacing-sm;
}
.CustomChakraButton--xl {
  @apply pt-spacing-lg;
  padding-right: 18px;
  @apply pb-spacing-lg;
  padding-left: 18px;
  @apply rounded-xl;
  @apply gap-spacing-sm;
}
.CustomChakraButton--2xl {
  @apply py-spacing-lg;
  padding-right: 22px;
  padding-left: 22px;
  @apply rounded-2xl;
  @apply gap-2.5;
}

.CustomChakraButton__text--sm {
  @apply font-semibold;
  @apply text-sm;
}
.CustomChakraButton__text--md {
  @apply font-semibold;
  @apply text-sm;
}
.CustomChakraButton__text--lg {
  @apply font-semibold;
  font-size: 16px;
  line-height: 24px;
}
.CustomChakraButton__text--xl {
  @apply font-semibold;
  font-size: 16px;
  line-height: 24px;
}
.CustomChakraButton__text--2xl {
  @apply font-semibold;
  @apply text-lg;
}
