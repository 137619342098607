.Basket {
    box-shadow: -4px 0 8px -4px #10182805;
}
.Basket__contextContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    max-height: calc(100vh - 6rem);
}

.Basket_button {
    position: sticky;
    bottom: 0;
    z-index: 10;
}

/* Fade in animation */
.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
}

/* Fade out animation */
.fade-out {
    opacity: 1;
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}

.alert {
    transition: opacity 0.5s;
}

.alert.hidden {
    opacity: 0;
}

.alert.visible {
    opacity: 1;
}