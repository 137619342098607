.CustomInput__state {
  @apply border;
  @apply border-grayLight-300;
  @apply rounded-lg;
}
.CustomInput__state:focus {
  @apply border-brand-300;
}
.CustomInput__state:disabled {
}
.CustomInput__state--filled {
}

.CustomInput__size--sm {
  @apply pt-spacing-md;
  @apply pr-spacing-lg;
  @apply pb-spacing-md;
  @apply pl-spacing-lg;
}
.CustomInput__size--md {
  padding: 10px 14px;
}
